import request from '@/utils/request.js'

//登录
export function Login(data){
    return request({
        method:'post',
        url:'/api/user/login',
        data
    })
}
//获取验证码
export const getVertifiCode=()=>{
    return request({
        method:'get',
        url:'/api/user/verificationCode',
    })
}

// 退出登录
export function exitLogin(){
    return request({
        url:'/api/user/logout',
        method:'post'
    });
}

export function leftMenu(){
    return request({
        method:'post',
        url:'/api/user/main'
    });
}

export function topInit(){
    return request({
        url:'/api/userMenu/addInit',
        method:'get'
    });
}

export function addTopList(data) {
    return request({
        url:'/api/userMenu/giveMenu',
        method:'post',
        data
    });
}

export function getTicket () {
    return request({
        url:'/api/user/getTicket',
        method:'post',
    });
}

export function whetherScan(data) {
    return request({
        url:'/api/user/whetherScan',
        method:'post',
        data,
    });
}

//获取验证码
export function  getCode(data) {
    return request({
        url:'/api/user/getVerificationCodeByPhone',
        method:'post',
        data
    });
}
//注册
export function  register(data) {
    return  request({
        url:'/api/user/register',
        method:'post',
        data
    });
}
